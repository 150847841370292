import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import styles from "./index.module.scss"
import CategoryLabel from "../categoryLabel"

export default class PostList extends React.Component {
  render() {
    const { posts } = this.props
    return (
      <section>
        <div
          className="uk-child-width-1-1 uk-child-width-1-3@s uk-flex-center uk-grid-match"
          uk-grid="true"
          uk-height-match="target: div.uk-card-header; row: false"
        >
          {posts.map(post => (
            <div
              className="uk-margin-auto uk-padding-remove　uk-grid-item-match"
              key={post.id}
            >
              <div
                className="uk-card uk-card-hover uk-card-default uk-card-small uk-box-shadow-medium uk-box-shadow-hover-xlarge"
                id={styles.card}
              >
                <div className="uk-card-media-top uk-cover-container">
                  <div className="uk-position-top-center" uk-cover="true">
                    <Link to={post.fields.slug} aria-label="post">
                      <Img
                        fluid={post.frontmatter.thumbnail.childImageSharp.fluid}
                      />
                    </Link>
                  </div>
                  <canvas height="300" width="500" />
                </div>
                <div className="uk-card-header">
                  <Link to={post.fields.slug} id={styles.post_title}>
                    {post.frontmatter.title}
                  </Link>
                </div>
                <hr id={styles.divide} />
                <div
                  className="uk-card-body uk-flex uk-flex-column uk-child-margin-small-bottom"
                  id={styles.card_body}
                >
                  <div className="uk-width-auto">
                    <span uk-icon="calendar" />
                    <small>{post.frontmatter.date}</small>
                  </div>
                  <CategoryLabel className="uk-width-auto" post={post} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    )
  }
}

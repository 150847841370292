import React from "react"
import { Link } from "gatsby"

const Pagination = props => {
  const { pageContext } = props
  const { previousPagePath, nextPagePath } = pageContext
  let previous = ""
  let next = ""
  if (previousPagePath) {
    previous = (
      <Link to={previousPagePath} style={{ fontSize: "1.3em" }}>
        <span className="uk-margin-small-right" uk-pagination-previous="true" />
        Previous
      </Link>
    )
  }
  if (nextPagePath) {
    next = (
      <Link to={nextPagePath} style={{ fontSize: "1.3em" }}>
        Next
        <span className="uk-margin-small-left" uk-pagination-next="true" />
      </Link>
    )
  }
  return (
    <ul className="uk-pagination uk-flex-center uk-margin">
      <li>{previous}</li>
      <li>{next}</li>
    </ul>
  )
}

export default Pagination

import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

import styles from "./index.module.scss"

const CategoryLabel = props => {
  const data = useStaticQuery(
    graphql`
      query {
        allCategoryJson {
          nodes {
            name
            slug
          }
        }
      }
    `
  )
  const { post } = props
  const { categories } = post.frontmatter
  const { nodes: cats } = data.allCategoryJson
  return (
    <div key={`${post.id} cat`} id={styles.catLabel}>
      {categories && categories.length ? (
        <div>
          <span uk-icon="icon: tag" />
          {categories.map(cat => {
            let link = "/"
            for (let i = 0; i < cats.length; i++) {
              if (cats[i].name === cat) {
                link = link + "category/" + cats[i].slug
                break
              }
            }
            return (
              <Link
                className="uk-label uk-margin-small-right uk-text-truncate"
                to={link}
              >
                {cat}
              </Link>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

export default CategoryLabel
